import React, { useState, useEffect } from "react";
import FsLightbox from "fslightbox-react";
import mapboxgl from "mapbox-gl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Modal, Box, Button } from "@mui/material";

import Calendar from "tui-calendar";
import "tui-calendar/dist/tui-calendar.css";

import "./app.css";
import img1 from "./img/1.jpg";
import img2 from "./img/2.jpg";
import img3 from "./img/3.jpg";
import img4 from "./img/4.jpg";
import img5 from "./img/5.jpg";
import img6 from "./img/6.jpg";
import img7 from "./img/7.jpg";
import img8 from "./img/8.jpg";
import img9 from "./img/9.jpg";

const imgList = [img1, img2, img3, img4, img5, img7, img8, img9];

function LightBox({ lightBox }) {
  return (
    <FsLightbox
      toggler={lightBox.toggler}
      slide={lightBox.slide}
      sources={imgList}
    />
  );
}

function BasicModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <Button onClick={handleOpen}>Open modal</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>hallo</Box>
      </Modal>
    </div>
  );
}

const MapBox = () => {
  mapboxgl.accessToken =
    "pk.eyJ1Ijoibmhvc3MyIiwiYSI6ImNrdW1zOHJudTAxMnYybnBmeGZxdjJkYm4ifQ.BiSq7DH_F0nSKlu_zU5_ww";

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: "map-container",
      style: "mapbox://styles/mapbox/satellite-streets-v11",
      center: [152.987105, -27.07611],
      zoom: 12,
    });
    map.scrollZoom.disable();
    map.addControl(new mapboxgl.NavigationControl());
  }, []);

  return <div id="map-container" className="map"></div>;
};

const CalendarBox = () => {
  const [calendar, setCalender] = useState();
  const [month, setMonth] = useState("");

  const updateMonth = () => {
    const monthStr = calendar
      .getDate()
      .toDate()
      .toLocaleString("default", { month: "long" });
    console.log(monthStr);
    setMonth(monthStr);
  };

  const nextMonth = () => {
    calendar.next();
    updateMonth();
  };

  const prevMonth = () => {
    calendar.prev();
    updateMonth();
  };

  useEffect(() => {
    const cal = new Calendar("#calendar", {
      defaultView: "month",
      usageStatistics: false,
      useDetailPopup: true,
      isReadOnly: true,
    });

    setCalender(cal);
    setMonth(
      cal.getDate().toDate().toLocaleString("default", { month: "long" })
    );

    fetch("https://kbl-schedule.l2s.workers.dev/", {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((jsonData) => {
        for (let i = 0; i < jsonData.bookings.length; i++) {
          let booking = jsonData.bookings[i];
          cal.createSchedules([
            {
              id: String(i),
              title: "booked",
              category: "time",
              start: booking.start,
              end: booking.finish,
            },
          ]);
        }
      });
  }, []);

  return (
    <div className="calendar-container">
      <h2>Schedule</h2>

      <div className="controls">
        <Button onClick={prevMonth} variant="outlined">Prev Month</Button>
        <p>{month}</p>
        <Button onClick={nextMonth} variant="outlined">Next Month</Button>
      </div>
      <div id="calendar"></div>
    </div>
  );
};

function App() {
  const [lightBox, setLightBox] = useState({
    toggler: false,
    slide: 1,
  });

  return (
    <div className="App">
      <header className="header">
        <img id="header-img" alt="title" src={img6} />
        <div className="main-title">
          <h1>Cessna 172N Skyhawk Hire</h1>
          <h2>Caboolture - YCAB</h2>
        </div>
        <div className="header-description">
          <p>
            Reliable and well maintained Cessna 172N Skyhawk available for Private Hire
            in one of SE QLD's most picturesque regions. Less than an hours
            drive from Brisbane.
          </p>
          <p>
            Comes with a beautiful refurbished interior and VFR instruments, the
            C172N is a practical and affordable way to fly.
          </p>
        </div>
      </header>

      <div className="images-list">
        {imgList.map((img, i) => {
          return (
            <div className="img-holder" key={i}>
              <img
                src={img}
                onClick={() => {
                  setLightBox({
                    toggler: !lightBox.toggler,
                    slide: i + 1,
                  });
                }}
                alt="172"
              />
            </div>
          );
        })}
      </div>

      <div className="details">
        <div className="hire-instructions">
          <h2>How To Hire</h2>

          <p>
            In order to hire at least an RPL and 5 hours on type is required.
            New hirers will be required to take a check flight and sign a hire
            agreement. Multiday hire is available but has minimum hour
            requirements. KBL has good availability. Get in touch to start the
            hiring process.
          </p>
          <p>Email: privatehire.kbl@gmail.com</p>
          <p>Phone: 0424 044 799</p>
        </div>

        <div className="pricing">
          <h2 className="price-title">Hirer friendly rates</h2>
          <div className="hire-price">
            <h1>$305</h1>
            <h2>/hour</h2>
          </div>
          <p>
            <FontAwesomeIcon icon={faCheck} /> Charged by engine hours (tacho)
          </p>
          <p>
            <FontAwesomeIcon icon={faCheck} /> Wet hire
          </p>
          <p>
            <FontAwesomeIcon icon={faCheck} /> Includes GST
          </p>
        </div>
      </div>

      <LightBox lightBox={lightBox} />

      <CalendarBox />

      <div className="map-info">
        <p>
          Caboolture - No landing fees, daytime only operations, close to Redcliffe, Caloundra, the
          Sunshine Coast, and Brisbane.
        </p>
      </div>
      <MapBox />
    </div>
  );
}

export default App;
